<template>
  <div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <div class="mt-4 mb-2 header">Sign-up for newsletter</div>
      <small class="text-success" v-if="mobileFlag">{{ mobileMessage }}</small>
      <!-- <b-form-group id="input-group-2" label="" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="form.name"
          required
          placeholder="Enter name"
        ></b-form-input>
      </b-form-group> -->

      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
        description=""
      >
        <b-form-input
          id="input-1"
          v-model="form.email"
          type="email"
          required
          placeholder="email@example.com"
          :validated="true"
        ></b-form-input>
      </b-form-group>

      <!-- <b-form-group id="input-group-1" label="" label-for="input-1">
        <b-form-input
          id="input-3"
          v-model="form.verifyEmail"
          type="email"
          required
          placeholder="Verify email"
          :state="verifyEmail"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">
          {{ verifyEmailMessage }}
        </b-form-invalid-feedback>
      </b-form-group> -->

      <div class="form-buttons d-flex justify-content-between">
        <b-button
          type="submit"
          variant="primary"
          class="primary-button flex-grow-1"
          >Submit</b-button
        >
        <b-button
          type="reset"
          variant="outline-info"
          class="secondary-button ml-4"
          >Reset</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: {
    communityBoard: Object
  },
  data() {
    return {
      form: {
        email: '',
        verifyEmail: '',
        checked: []
      },
      show: true,
      mobileMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      mobileFlag: 'getMobileFlag'
    }),
    verifyEmail() {
      return this.form.email === ''
        ? null
        : this.form.email === this.form.verifyEmail
        ? true
        : false
    },
    verifyEmailMessage() {
      return this.verifyEmail === false ? 'emails do not match' : ''
    }
  },
  methods: {
    async createUser(form) {
      let { email } = form

      const params = {
        email: email,
        communityID: this.communityBoard['communityID']
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/createUser`,
        params
      )

      const message = response.data.message
      let messageType = 'success'

      if (message === 'User already exists') {
        this.mobileMessage = `${email} already exists for ${this.communityBoard.normalizedName}`
      } else if (message === 'User added') {
        this.mobileMessage = `Successfully signed up for ${this.communityBoard.normalizedName}`
      } else {
        this.mobileMessage = `Failed to sign up for ${this.communityBoard.normalized}`
        messageType = 'error'
      }

      this.$store.commit('setNotificationObject', {
        show: true,
        message: this.mobileMessage,
        type: messageType
      })
    },
    onSubmit(evt) {
      evt.preventDefault()

      this.createUser(this.form)
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.verifyEmail = ''
      this.form.checked = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.mobileMessage = ''
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>

<style lang="scss">
.form-control {
  background: #f1f2f3;
  border-width: 0px;

  ::placeholder {
    color: #b9babb;
  }
}
// .form{

//   input{
//     background: #F1F2F3;
//   }
//
// }
</style>
