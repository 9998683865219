<template>
  <div name="newsletter" class="newsletter top-container">
    <div class="bg-light landing-box">
      <div class="container-fluid curve-block-container w-75">
        <div class="row mt-4 row-header">
          <div
            class="col-lg-6 col-md-6 col-sm-12 text-center text-md-left mb-4"
          >
            <h1>Newsletter</h1>
            <p>
              We send out a weekly newsletter with summaries and links to full
              Community Board Transcripts. If you want to sign-up, please enter
              your email below and select a community board you want to receive
              updates for.
            </p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 text-center">
            <img
              :src="newsletterImage"
              alt="landing-image"
              class="landing-image"
            />
          </div>
        </div>

        <!-- community board signup -->
        <div class="row spacing-margin-lg"></div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-2">
            <h5 class="text-center">Community Boards Active on Block Party</h5>
            <img
              :src="communityBoardActiveImage"
              alt="community-board-information"
              class="community-board-information"
            />
          </div>
          <div
            class="col-lg-6 col-md-6 col-sm-12 text-center mx-auto d-flex-content-center mb-4"
          >
            <div>
              <h4>Select a Community Board</h4>
              <p class="text-center text-md-justify">
                The list is filtered by the community boards that post meetings
                publicly on Youtube.
              </p>
              <b-form-select
                v-model="communityBoardSelect"
                :options="communityBoardOptions"
                @change="handleCommunityBoardSelect"
              ></b-form-select>
            </div>
          </div>
        </div>
        <div class="row spacing-margin-lg"></div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">
            <div class="title">{{ this.communityBoard.normalizedName }}</div>

            <div class="d-flex flex-row align-items-center">
              <!-- <socialMedia
                v-if="this.communityBoard.youtubeChannelURL.length > 0"
                :tag="'youtube'"
                :url="
                  'https://www.youtube.com/channel/' +
                  this.communityBoard.youtubeChannelURL
                "
              />
              <socialMedia
                :tag="'twitter'"
                :url="this.communityBoard.twitterURL"
              /> -->
            </div>

            <contactForm
              v-if="this.communityBoard.status === 'active'"
              :communityBoard="communityBoard"
            />
          </div>
        </div>

        <notification />
        <!-- <div class="row pt-2" v-if="showErrorMessage">
          <div class="col-12 text-center">
            <h3>😟</h3>
            <div class="text-danger">
              sadly, this community board does not make their meetings
              accessible on YouTube.
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// components
import contactForm from '@/components/contact_form.vue'
import notification from '@/components/notification.vue'

export default {
  data() {
    return {
      logo: require('@/assets/img/logo.png'),
      newsletterImage: require('@/assets/img/newsletter_icon.png'),
      communityBoardActiveImage: require('@/assets/img/community_boards_active.png'),
      showCommunityBoardInfo: false,
      showErrorMessage: false,
      communityBoard: {},
      communityBoardSelect: null
    }
  },
  components: { contactForm, notification },
  computed: {
    ...mapGetters({
      mobileFlag: 'getMobileFlag',
      communityBoardInfo: 'getCommunityBoardInfo'
    }),
    communityBoardOptions() {
      return this.communityBoardInfo
        .filter(cb => cb.status === 'active')
        .reduce((accumulator, d) => {
          accumulator.push({ value: d.communityID, text: d.normalizedName })
          return accumulator
        }, [])
        .sort(function (a, b) {
          return a.text.localeCompare(b.text, undefined, {
            numeric: true,
            sensitivity: 'base'
          })
        })
    }
  },
  methods: {
    handleCommunityBoardSelect() {
      this.communityBoard = this.communityBoardInfo.find(
        cb => cb.communityID === this.communityBoardSelect
      )

      if (this.communityBoard) {
        if (this.communityBoard.status === 'active') {
          this.showCommunityBoardInfo = true
        } else {
          this.showErrorMessage = true
        }
      } else {
        this.showCommunityBoardInfo = false
        this.showErrorMessage = true
      }
    }
  }
}
</script>

<style lang="scss">
.row-header {
  padding: 4rem;
}
.landing-image {
  width: 100%;
  min-width: 150px;
  max-width: 250px;
  height: auto;
}
.community-board-information {
  width: 100%;
  height: auto;
  min-width: 200px;
  max-width: 400px;
  border-radius: 8px;
}
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
}

.landing-box {
  padding-bottom: 5rem;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.sign-up-box {
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .row-header {
    padding: 6rem;
  }
  .landing-image {
    min-width: 200px;
    max-width: 300px;
  }
  .community-board-information {
    min-width: 250px;
    max-width: 400px;
  }
}
</style>
